import { required, maxLength } from 'vuelidate/lib/validators';
import { onlyAlphanumeric4 } from '@/_validations/validacionEspeciales'

const nameLength = (value) => {
  if (typeof value === 'string') {
    let Array = value.split(' ');
    return !Array.some(item => item.length > 20);
  } else {
    return true;
  }
};

export default (FgConfigurations) => {
  let Configurations = FgConfigurations ? { required } : false;
  return {
    EventGpoId: { required },
    EventNameEs: { required, maxLength: maxLength(60), nameLength, onlyAlphanumeric4 },
    EventNameEn: { required, maxLength: maxLength(60), nameLength, onlyAlphanumeric4 },
    Acronym: { required, maxLength: maxLength(10), onlyAlphanumeric4 },
    Color: { required },
    Configurations: Configurations,
    Status: { required },
  }
}